import axios from 'axios';
import apiRequest from '../helpers/api';
import {
  setProfileInitialData,
  setProfile,
  startSetProfile,
  resetProfile
} from './profile';

export const login = uid => ({
  type: 'LOGIN',
  uid
});

export const loginStatus = message => ({
  type: 'LOGIN_STATUS',
  message
});

export const startLogin = data => {
  return (dispatch, getState) => {
    const url = 'api-token-auth/';

    const config = apiRequest('POST', url, data);

    return axios(config)
      .then(data => {
        const uid = data.data.token;
        return dispatch(startSetProfile(uid))
          .then(() => dispatch(login(uid)))
          .catch(() => dispatch(loginStatus('Login failed.')));
      })
      .catch(error => {
        try {
          const res = error.response.data;
          const hasErrors =
            res.non_field_errors[0].indexOf('credentials') !== -1;
          return hasErrors
            ? dispatch(
                loginStatus('Unable to log in with provided credentials.')
              )
            : dispatch(loginStatus('API call error.'));
        } catch (err) {
          return dispatch(loginStatus('API call error.'));
        }
      });
  };
};

export const startLoginUsingToken = authToken => {
  return dispatch => {
    const url = 'profile/';

    const config = apiRequest('GET', url);

    config.headers['Authorization'] = `Token ${authToken}`;

    return axios(config).then(data => {
      const response = data.data;

      dispatch(setProfileInitialData(response.group_id, response.account_id));
      dispatch(setProfile(response));
      dispatch(login(authToken));
    });
  };
};

export const requestResetPassword = email => {
  return () => {
    const url = 'request_reset_password/';

    const config = apiRequest('POST', url, { email });

    return axios(config);
  };
};

export const validateResetToken = token => {
  return () => {
    const url = 'validate_reset_password_token/';

    const config = apiRequest('POST', url, { token });

    return axios(config);
  };
};

export const startResetPassword = data => {
  return () => {
    const url = 'reset_password/';

    const config = apiRequest('POST', url, data);

    return axios(config);
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogout = () => {
  return dispatch => {
    dispatch(resetProfile());
    dispatch(logout());
  };
};
