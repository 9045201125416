import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { startLogin, loginStatus } from '../../../actions/auth';
import { AppContext } from '../../../AppContext';
import logo from '../../../assets/img/brand/logo.svg';

class Login extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleLoginEvent = this.handleLoginEvent.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.props.loginStatus('');
    this.setState({ [name]: value });
  }

  handleLanguageChange(lang) {
    this.context.setActiveLanguage(lang);
  }

  handleLoginEvent(e) {
    e.preventDefault();

    const data = { ...this.state };

    this.props.loginStatus('Checking credentials, please wait...');

    this.props.startLogin(data);

    this.setState({ password: '' });
  }

  render() {
    const { translate } = this.context;
    const { username, password } = this.state;
    const disableSubmit = username.length < 3 || password.length < 6;

    return (
      <div className="login-page flex-row align-items-center login-img">
        <Card className="login-page-content">
          <CardBody>
            <div className="login-page-logo">
              <img src={logo} alt="Logo" height="80px" />
            </div>
            <p className="text-muted">
              {this.props.message ? (
                <span>{this.props.message}</span>
              ) : (
                <span>{translate('login_sign_in_text')}</span>
              )}
            </p>
            <form onSubmit={this.handleLoginEvent}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-user" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="username"
                  autoFocus
                  autoComplete="off"
                  value={username}
                  onChange={this.handleInputChange}
                  placeholder={translate('username')}
                  required
                />
              </InputGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-lock" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="password"
                  name="password"
                  autoComplete="off"
                  value={password}
                  onChange={this.handleInputChange}
                  placeholder={translate('password')}
                  required
                />
              </InputGroup>
              <Link
                to="/reset_password/forgot"
                className="float-right mb-2 mt-1"
              >
                {`${translate('password_reset_forgot')}?`}
              </Link>
              <div>
                <Button
                  type="submit"
                  color="orange"
                  className="btn btn-orange btn-lg btn-block"
                  disabled={disableSubmit}
                >
                  {translate('login_sign_in')}
                </Button>
              </div>
              <span className="btn-block text-center mt-2">
                <a
                  href="https://www.telsmart.eu/support"
                  color="primary"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {translate('login_help')}
                </a>
              </span>
              <span className="btn-block text-center">
                <a
                  href={process.env.REACT_APP_WEBINAR_URL}
                  color="primary"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {translate('login_webinar')}
                </a>
              </span>
            </form>
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('en')}
              >
                English
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('nl')}
              >
                Nederlands
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('fr')}
              >
                Français
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.auth.message || ''
  };
};

const mapDispatchToProps = dispatch => ({
  startLogin: data => dispatch(startLogin(data)),
  loginStatus: data => dispatch(loginStatus(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
